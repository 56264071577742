import React from 'react';
import PropTypes from 'prop-types';

import styles from './Caption.module.css';

const Caption = ({ children }) => {
  return (
    <div className={styles.caption}>
      {children}
    </div>
  );
};

Caption.propTypes = {
  children: PropTypes.node,
};

export default Caption;
