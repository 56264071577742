import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';
import Caption from '../components/Caption';

const VintagePage = ({ data }) => {
  return (
    <Layout>
      <Section heading="2019 vintage report">
        <p>
          The 2019 Vintage was our second very warm, dry vintage in a row here
          at Mount Mary. Vintage commenced on the 15th of February and
          concluded on the 29th of March, with the Mourvedre the last variety
          picked. We were extremely thankful for the generous amount of rain
          mother nature provided around mid/ late December, setting us up beautifully
          leading into January. Bud fruitfulness, and resultant tonnages were lower
          than in 2018.
        </p>
        <p>
          This was seen as a blessing with less time required to drop fruit
          during the green harvest period. We do not see the 2019 season
          particularly favouring any one variety or wine, with a very pleasing
          quality spread across all varieties grown here at Mount Mary.
        </p>
        <p>
          We were particularly pleased this vintage with how the team performed
          and worked together. We just love vintage time and this years’ team
          certainly performed like an exceptionally well oiled machine. It was
          so great to share the 2019 season with such a sensational bunch of
          people!
        </p>
        <Img fluid={data.image.childImageSharp.fluid} />
        <Caption>Vintage 2019 tractor-side catch-ups....Claire, Chris, Alexei, Sam, David and Marco</Caption>
      </Section>
      <Pagination
        forwardLink="/events"
        backLink="/jwdm"
      />
    </Layout>
  );
};

VintagePage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    image: file(relativePath: { eq: "vintage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default VintagePage;
